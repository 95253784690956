import React, {useState} from "react";
import Correspondent from "../components/Correspondent";
import correspondent from "../components/Correspondent";
import {Link} from "react-router-dom";

const Correspondents = () => {
    const max = 3;
    const [correspondents, setCorrespondents] = useState(JSON.parse(localStorage.getItem('correspondents') || '[]'));

    const addCorrespondent = () => {
        let corres = [...correspondents];
        corres.push({
            'name': '',
            'number': ''
        })

        setCorrespondents(corres);
    }

    return (
        <div className='container'>
            <nav className="navbar bg-body-tertiary">
                <div className="container-fluid">
                    <Link to='/' className="navbar-brand mb-0 h1" >Retour</Link>
                </div>
            </nav>
            <div className='text-center mt-5'>
                <h4>Mes correspondants d'urgences</h4>
            </div>
            <div className='row'>
                {correspondents.length > 0 && correspondents.map((v: any, i: number) => (
                    <div className='col-md mt-4' key={i}>
                        <Correspondent
                            correspondents={correspondents}
                            number={i + 1} index={i}
                            setCorrespondents={setCorrespondents}/>
                    </div>
                ))}
            </div>
            <div className=''>
                {correspondents.length < max &&
                    <div className='d-flex justify-content-center mt-5'>
                        <button className='btn btn-primary' onClick={addCorrespondent}>
                            Ajouter un correspondant
                        </button>
                    </div>}
            </div>
        </div>
    )
}

export default Correspondents;