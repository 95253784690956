import React, {useState} from "react";
import PhoneInput from 'react-phone-number-input/input';
import {E164Number, isValidPhoneNumber} from "libphonenumber-js";
import {toast} from "react-toastify";

const Correspondent = ({correspondents, number = 0, index = 1, setCorrespondents}: {
    correspondents: any,
    number: number,
    index: number,
    setCorrespondents: any
}) => {
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState(correspondents[index].name);
    const [value, setValue] = useState<E164Number | undefined>(correspondents[index].number);

    const setData = () => {
        let tmp = [...correspondents];
        tmp[index].name = name;

        if (name === '') {
            toast.error('Le nom du correspondant est vide', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return;
        }

        if (isValidPhoneNumber(value as string)) {
            tmp[index].number = value;
            setCorrespondents(tmp);
            localStorage.setItem('correspondents', JSON.stringify(tmp));
            setEdit(false);
            toast.success(`Correspondant N°${number} enregistrer avec succès`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error('Numéro de téléphone invalide', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const deleteCorrespondent = () => {
        if(window.confirm('Voulez vous vraiment supprimer ce correspondants ?')){
            let tmp = [...correspondents];
            tmp.splice(index, 1);
            setCorrespondents(tmp);
            localStorage.setItem('correspondents', JSON.stringify(tmp));
        }
    }

    return (
        <div className='position-relative'>
            <div className='position-absolute top-0 end-0 pe-2'>
                <i
                    className="fa-solid fa-circle-xmark text-danger fs-2 pointer-event"
                    onClick={deleteCorrespondent}></i>
            </div>
            <h5>Correspondant Nº{number}</h5>
            <input placeholder='Nom et prénom(s)'
                   type='text'
                   className='form-control mb-3'
                   value={name}
                   onChange={event => {
                       setEdit(true);
                       setName(event.target.value)
                   }}/>
            <PhoneInput
                international={false}
                defaultCountry="FR"
                countries={["FR"]}
                country="FR"
                value={value}
                className='form-control'
                placeholder='Numero de téléphone'
                onChange={value => {
                    setEdit(true);
                    setValue(value)
                }}/>
            {edit ? (
                <div className='d-flex justify-content-center'>
                    <button className='btn btn-success mt-3' onClick={setData}>Sauvegarder</button>
                </div>
            ) : null}
        </div>
    )
}

export default Correspondent;