import React from 'react';
import './App.css';

import {HashRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/Main";
import Correspondents from "./pages/Correspondents";
import {ToastContainer} from "react-toastify";

const App = () => {
    return (
        <HashRouter>
            <ToastContainer/>
            <Routes>
                <Route path='/' element={<Main/>}/>
                <Route path='/correspondents' element={<Correspondents/>}/>
            </Routes>
        </HashRouter>
    )
}

export default App;