import React, {useEffect, useState} from "react";
import {E164Number, isValidPhoneNumber} from "libphonenumber-js";
import {useGeolocated} from "react-geolocated";
import {toast} from "react-toastify";
import PhoneInput from "react-phone-number-input/input";
import logo from "../assets/sos.png";
import {Link} from "react-router-dom";

const Main = () => {
    const numb = localStorage.getItem('phone') || '';
    const [number, setNumber] = useState<E164Number | undefined>(numb);
    const [ok,setOk] = useState(false);
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
            },
            userDecisionTimeout: 15000,
        });

    const handleSave = (e: React.MouseEvent) => {
        if (typeof number !== 'undefined' && isValidPhoneNumber(number.toString())) {
            localStorage.setItem('phone', number);
            setOk(true);
        } else {
            toast.error('Numéro de téléphone invalide', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleSendSMS = (e: React.MouseEvent<HTMLImageElement>) => {
        if (coords?.latitude !== null) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({content: `Demande SOS venant du numéro ${number},  URL: https://www.google.com/maps?q=${coords?.latitude},${coords?.longitude}`})
            };
            fetch('https://active-business.eu/api/v2/pwa/send-alarm', requestOptions)
                .then(response => response.json())
                .then(data => {
                        if (data.success === true) {
                            toast.success('SMS d\'alarme envoyé !', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        } else {
                            toast.error('Une erreur est survenu, veuillez contactez un administrateur (Erreur API)', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                );
        } else {
            toast.error('Localisation impossible', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    useEffect(() => {
        if(typeof number !== 'undefined' && isValidPhoneNumber(number)){
            setOk(true);
        }
    }, []);

    return !isGeolocationAvailable ? (
        <div className="container text-center mt-5 pt-5 vh-100">
            <div className="bg-danger p-5 rounded">
                Impossible d'utiliser la geolocalisation
            </div>
        </div>
    ) : !isGeolocationEnabled ? (
        <div className="container text-center mt-5 pt-5 vh-100">
            <div className="bg-danger p-5 rounded">
                Veuillez activer votre service de geolocalisation
            </div>
        </div>
    ) : !ok ? (
        <div className="container text-center mt-5 pt-5">
            Veuillez entrer votre numéro de téléphone
            <PhoneInput
                className="form-control mt-3 mb-3"
                country="FR"
                value={number}
                defaultCountry="FR"
                countries={["FR"]}
                onChange={setNumber}/>
            <button className="btn btn-primary mt-3" onClick={handleSave}>Valider
            </button>
        </div>
    ) : (
        <div className="container">
            <div className="d-flex justify-content-center ">
                <img
                    onDoubleClick={handleSendSMS}
                    src={logo}
                    className="img-fluid mt-5"
                    style={{
                        width: '75%'
                    }} alt=''/>
            </div>
            <div className='text-center mt-5'>
                <span>
                    Double cliquez sur le logo pour lancer un SMS d'alarme a la centrale de téléassistance
                </span>
            </div>
            <div className="dropup position-absolute bottom-0 end-0 rounded-circle m-5">
                <Link to={'/correspondents'} type="button" className="btn btn-outline-dark btn-lg"
                        aria-expanded="false" aria-haspopup="true">
                    <i className="fa-solid fa-gear fs-2"></i>
                </Link>
            </div>
        </div>
    )
}

export default Main;